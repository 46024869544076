import React from "react"
import parse from 'html-react-parser';
import UserManagement from "../UserManagement";

const TouContent = () => {
  const content = UserManagement.getEULAContent();
  // eslint-disable-next-line react/no-danger
  // return <div dangerouslySetInnerHTML={{ __html: content }} />;
  return <div>{parse(content)}</div>;
};

export default TouContent;
