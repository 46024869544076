import React from 'react';
import "../css/OpenLicense.css";
import libraries from './libraries';

const OpenLicense = () => {
  return (
    <div className="container">
      <div className="heading">
        Overview of Acknowledgements and License Texts for open source software
      </div>
      <div className="over-view">
        This overview provides license information and acknowledgements for the following product(s):
      </div>
      <div className="over-view">1. Interact Sports Recreational</div>
      <div className="sub-heading">Interact Sports Recreational Application Webportal</div>
      <div className="over-view">
        This Interact Sports Recreational Application Webportal makes use of software licensed under an open source
        license. When interacting with the webportal you may receive software that is executed in your
        browser.
        <br />
        The list below provides an overview of acknowledgements/license texts of open source software that
        may be distributed during your interactions with the webportal used in this product.
      </div>
      <div className="line" />

      {libraries.map((library) => (
        <div className="package-entry" key={library.name}>
          <p><span className="label">Name: </span> {library.name}</p>
          <p><span className="label">Version: </span> {library.version}</p>
          <p>
            <span className="label">License: </span>
            <a className='licenseLink'
              target="_blank" href={library.licenseUrl} rel="noopener noreferrer"
            >
              {library.license}
            </a>
          </p>
          <p><span className="label">Project URL: </span> {library.projectUrl}</p>
          <div className="separator">_ _ _ _ _ _ _ _ _ _</div>
        </div>))}
    </div>
  );
};

export default OpenLicense;
