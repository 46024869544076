import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppLanding from "./components/AppLanding";
import MainDashboard from "./MainDashboard";
import Monitor from "./Monitor/Monitor";
import NotFound from "./components/NotFound";
import About from "./About/About";
import AccessDenied from "./AccessDenied";

const Routes = () => (
  <Switch>
    <Route path="/Sites/:siteId/:app/:fieldId?" component={AppLanding} />
    <Route exact path="/Sites" component={MainDashboard} />
    <Route path="/Monitor/:app?" component={Monitor} />
    <Route exact path="/NotFound" component={NotFound} />
    <Route exact path={["/About", "/License_Agreement", "/Open_License"]} component={About} />
    <Route exact path="/AccessDenied" component={AccessDenied} />
    <Route exact path="/">
      <Redirect to="/Sites" />
    </Route>
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
