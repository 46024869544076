// List of libraries with their details
const libraries = [
  {
    name: "Antlr3.Runtime",
    version: "3.5.1",
    license: "BSD 3",
    licenseUrl: "/backend-license/Antlr3.Runtime.txt",
    projectUrl: "https://github.com/antlr/antlrcs"
  },
  {
    name: "@xtuc/long",
    version: "4.2.2",
    license: "Apache 2.0",
    licenseUrl: "/frontend-license/@xtuclong.txt",
    projectUrl: "https://github.com/dcodeIO/long.js"
  },
  {
    name: "@webassemblyjs/leb128",
    version: "1.8.5",
    license: "Apache 2.0",
    licenseUrl: "/frontend-license/@webassemblyjsleb128.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "Owin",
    version: "1.0.0",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/Owin.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "tslib",
    version: "1.10.0",
    license: "0BSD",
    licenseUrl: "/frontend-license/tslib.txt",
    projectUrl: "https://github.com/Microsoft/tslib"
  },
  {
    name: "Microsoft.Owin.Security.Jwt",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurityJwt.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin.Host.SystemWeb",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurityJwt.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin.Security",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurity.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwin.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin.Security.OAuth",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurityOAuth.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin.Security.Cookies",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurityCookies.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "Microsoft.Owin.Security.OpenIdConnect",
    version: "4.1.1",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/MicrosoftOwinSecurityOpenIdConnect.txt",
    projectUrl: "https://github.com/aspnet/AspNetKatana"
  },
  {
    name: "log4net",
    version: "2.0.12",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/log4net.txt",
    projectUrl: "https://github.com/apache/logging-log4net"
  },
  {
    name: "Unity",
    version: "5.11.9",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/Unity.txt",
    projectUrl: "https://github.com/unitycontainer/unity"
  },
  {
    name: "AWSSDK.Core",
    version: "3.5.1.47",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/AWSSDKCore.txt",
    projectUrl: "https://github.com/aws/aws-sdk-net/"
  },
  {
    name: "AWSSDK.S3",
    version: "3.5.6.3",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/AWSSDKS3.txt",
    projectUrl: "https://github.com/aws/aws-sdk-net/"
  },
  {
    name: "AWSSDK.SQS",
    version: "3.5.0.47",
    license: "Apache 2.0",
    licenseUrl: "/bckend-license/AWSSDKSQS.txt",
    projectUrl: "https://github.com/aws/aws-sdk-net/"
  },
  {
    name: "d3-scale-chromatic",
    version: "1.5.0",
    license: "ISC",
    licenseUrl: "/frontend-license/d3-scale-chromatic.txt",
    projectUrl: "https://github.com/d3/d3-scale-chromatic"
  },
  {
    name: "atob",
    version: "2.0.3",
    license: "Apache 2.0 OR MIT",
    licenseUrl: "/frontend-license/atob.txt",
    projectUrl: "https://git.coolaj86.com/coolaj86/atob.js.git"
  },
  {
    name: "rw",
    version: "1.3.3",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/rw.txt",
    projectUrl: "https://github.com/mbostock/rw"
  },
  {
    name: "esrecurse",
    version: "4.2.1",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/esrecurse.txt",
    projectUrl: "https://github.com/estools/esrecurse"
  },
  {
    name: "mississippi",
    version: "3.0.0",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/mississippi.txt",
    projectUrl: "https://github.com/max-mapper/mississippi"
  },
  {
    name: "uri-js",
    version: "4.2.2",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/uri-js.txt",
    projectUrl: "https://github.com/garycourt/uri-js"
  },
  {
    name: "d3-array",
    version: "1.2.4",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-array.txt",
    projectUrl: "https://github.com/d3/d3-array"
  },
  {
    name: "d3-collection",
    version: "1.0.7",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-collection.txt",
    projectUrl: "https://github.com/d3/d3-collection"
  },
  {
    name: "d3-random",
    version: "1.1.2",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-random.txt",
    projectUrl: "https://github.com/d3/d3-random"
  },
  {
    name: "d3-fetch",
    version: "1.1.2",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-fetch.txt",
    projectUrl: "https://github.com/d3/d3-fetch"
  },
  {
    name: "d3-chord",
    version: "1.0.6",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-chord.txt",
    projectUrl: "https://github.com/d3/d3-chord"
  },
  {
    name: "d3-axis",
    version: "1.0.12",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-axis.txt",
    projectUrl: "https://github.com/d3/d3-axis"
  },
  {
    name: "hoist-non-react-statics",
    version: "3.2.1",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/hoist-non-react-statics.txt",
    projectUrl: "https://github.com/mridgway/hoist-non-react-statics"
  },
  {
    name: "d3-scale",
    version: "2.2.2",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-scale.txt",
    projectUrl: "https://github.com/d3/d3-scale"
  },
  {
    name: "eslint-scope",
    version: "4.0.3",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/eslint-scope.txt",
    projectUrl: "https://github.com/eslint/js"
  },
  {
    name: "eslint-scope",
    version: "5.0.0",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/eslint-scope.txt",
    projectUrl: "https://github.com/eslint/js"
  },
  {
    name: "d3-zoom",
    version: "1.8.3",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-zoom.txt",
    projectUrl: "https://github.com/d3/d3-zoom"
  },
  {
    name: "estraverse",
    version: "4.3.0",
    license: "BSD-2-Clause",
    licenseUrl: "/frontend-license/estraverse.txt",
    projectUrl: "https://github.com/estools/estraverse"
  },
  {
    name: "d3-time",
    version: "1.1.0",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-time.txt",
    projectUrl: "https://github.com/d3/d3-time"
  },
  {
    name: "d3-drag",
    version: "1.2.5",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-drag.txt",
    projectUrl: "https://github.com/d3/d3-drag"
  },
  {
    name: "d3-ease",
    version: "1.0.6",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-ease.txt",
    projectUrl: "https://github.com/d3/d3-ease"
  },
  {
    name: "d3-selection",
    version: "1.4.1",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-selection.txt",
    projectUrl: "https://github.com/d3/d3-selection"
  },
  {
    name: "d3-timer",
    version: "1.0.10",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-timer.txt",
    projectUrl: "https://github.com/d3/d3-timer"
  },
  {
    name: "d3-dispatch",
    version: "1.0.6",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-dispatch.txt",
    projectUrl: "https://github.com/d3/d3-dispatch"
  },
  {
    name: "d3-hierarchy",
    version: "1.1.9",
    license: "BSD-3-Clause",
    licenseUrl: "/frontend-license/d3-hierarchy.txt",
    projectUrl: "https://github.com/d3/d3-hierarchy"
  },
  {
    name: "d3-quadtree",
    version: "1.0.7",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-quadtree.txt",
    projectUrl: "https://github.com/d3/d3-quadtree"
  },
  {
    name: "d3-path",
    version: "1.0.9",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-path.txt",
    projectUrl: "https://github.com/d3/d3-path"
  },
  {
    name: "d3-dsv",
    version: "1.2.0",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-dsv.txt",
    projectUrl: "https://github.com/d3/d3-dsv"
  },
  {
    name: "d3-shape",
    version: "1.3.7",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-shape.txt",
    projectUrl: "https://github.com/d3/d3-shape"
  },
  {
    name: "d3-transition",
    version: "1.3.2",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-transition.txt",
    projectUrl: "https://github.com/d3/d3-transition"
  },
  {
    name: "d3-interpolate",
    version: "1.4.0",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-interpolate.txt",
    projectUrl: "https://github.com/d3/d3-interpolate"
  },
  {
    name: "d3-brush",
    version: "1.1.5",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-brush.txt",
    projectUrl: "https://github.com/d3/d3-brush"
  },
  {
    name: "d3-format",
    version: "1.4.3",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-format.txt",
    projectUrl: "https://github.com/d3/d3-format"
  },
  {
    name: "d3-time-format",
    version: "2.2.3",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3-time-format.txt",
    projectUrl: "https://github.com/d3/d3-time-format"
  },
  {
    name: "d3",
    version: "5.15.0",
    license: "BSD 2",
    licenseUrl: "/frontend-license/d3.txt",
    projectUrl: "https://github.com/d3/d3"
  },
  {
    name: "terser",
    version: "4.6.3",
    license: "BSD 2",
    licenseUrl: "/frontend-license/terser.txt",
    projectUrl: "https://github.com/terser/terser"
  },
  {
    name: "hoist-non-react-statics",
    version: "3.3.2",
    license: "BSD 2",
    licenseUrl: "/frontend-license/hoist-non-react-statics.txt",
    projectUrl: "https://github.com/mridgway/hoist-non-react-statics"
  },
  {
    name: "d3-geo",
    version: "1.11.9",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-geo.txt",
    projectUrl: "https://github.com/d3/d3-geo"
  },
  {
    name: "warning",
    version: "3.0.0",
    license: "BSD 3",
    licenseUrl: "/frontend-license/warning.txt",
    projectUrl: "https://github.com/BerkeleyTrue/warning"
  },
  {
    name: "invariant",
    version: "2.2.2",
    license: "BSD 3",
    licenseUrl: "/frontend-license/invariant.txt",
    projectUrl: "https://github.com/zertosh/invariant"
  },
  {
    name: "Antlr",
    version: "3.5.0.2",
    license: "BSD 3",
    licenseUrl: "/backend-license/Antlr.txt",
    projectUrl: "https://github.com/antlr/antlrcs"
  },
  {
    name: "source-map",
    version: "0.6.1",
    license: "BSD 3",
    licenseUrl: "/frontend-license/source-map.txt",
    projectUrl: "https://github.com/mozilla/source-map"
  },
  {
    name: "d3-voronoi",
    version: "1.1.4",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-voronoi.txt",
    projectUrl: "https://github.com/d3/d3-voronoi"
  },
  {
    name: "d3-contour",
    version: "1.3.2",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-contour.txt",
    projectUrl: "https://github.com/d3/d3-contour"
  },
  {
    name: "@xtuc/ieee754",
    version: "1.2.0",
    license: "BSD 3",
    licenseUrl: "/frontend-license/@xtucieee754.txt",
    projectUrl: "https://github.com/feross/ieee754"
  },
  {
    name: "d3-force",
    version: "1.2.1",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-force.txt",
    projectUrl: "https://github.com/d3/d3-force"
  },
  {
    name: "ieee754",
    version: "1.1.13",
    license: "BSD 3",
    licenseUrl: "/frontend-license/ieee754.txt",
    projectUrl: "https://github.com/feross/ieee754"
  },
  {
    name: "react-transition-group",
    version: "2.9.0",
    license: "BSD 3",
    licenseUrl: "/frontend-license/react-transition-group.txt",
    projectUrl: "https://github.com/reactjs/react-transition-group"
  },
  {
    name: "d3-color",
    version: "1.4.0",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-color.txt",
    projectUrl: "https://github.com/d3/d3-color"
  },
  {
    name: "d3-polygon",
    version: "1.0.6",
    license: "BSD 3",
    licenseUrl: "/frontend-license/d3-polygon.txt",
    projectUrl: "https://github.com/d3/d3-polygon"
  },
  {
    name: "serialize-javascript",
    version: "2.1.2",
    license: "BSD 3",
    licenseUrl: "/frontend-license/serialize-javascript.txt",
    projectUrl: "https://github.com/yahoo/serialize-javascript"
  },
  {
    name: "FluentNHibernate",
    version: "3.1.0",
    license: "BSD 3",
    licenseUrl: "/backend-license/FluentNHibernate.txt",
    projectUrl: "https://github.com/nhibernate/fluent-nhibernate"
  },
  {
    name: "Iesi.Collections",
    version: "4.0.4",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/IesiCollections.txt",
    projectUrl: "https://github.com/nhibernate/iesi.collections"
  },
  {
    name: "inherits",
    version: "2.0.1",
    license: "ISC",
    licenseUrl: "/frontend-license/inherits.txt",
    projectUrl: "https://github.com/isaacs/inherits"
  },
  {
    name: "wrappy",
    version: "1.0.2",
    license: "ISC",
    licenseUrl: "/frontend-license/wrappy.txt",
    projectUrl: "https://github.com/isaacs/wrappy"
  },
  {
    name: "fs.realpath",
    version: "1.0.0",
    license: "ISC",
    licenseUrl: "/frontend-license/fsrealpath.txt",
    projectUrl: "https://github.com/isaacs/fs.realpath"
  },
  {
    name: "once",
    version: "1.4.0",
    license: "ISC",
    licenseUrl: "/frontend-license/once.txt",
    projectUrl: "https://github.com/isaacs/once"
  },
  {
    name: "inherits",
    version: "2.0.3",
    license: "ISC",
    licenseUrl: "/frontend-license/inherits.txt",
    projectUrl: "https://github.com/isaacs/inherits"
  },
  {
    name: "inflight",
    version: "1.0.6",
    license: "ISC",
    licenseUrl: "/frontend-license/inflight.txt",
    projectUrl: "https://github.com/isaacs/inflight"
  },
  {
    name: "unique-slug",
    version: "2.0.0",
    license: "ISC",
    licenseUrl: "/frontend-license/unique-slug.txt",
    projectUrl: "https://github.com/npm/unique-slug"
  },
  {
    name: "run-queue",
    version: "1.0.3",
    license: "ISC",
    licenseUrl: "/frontend-license/run-queue.txt",
    projectUrl: "https://github.com/iarna/run-queue"
  },
  {
    name: "move-concurrently",
    version: "1.0.1",
    license: "ISC",
    licenseUrl: "/frontend-license/move-concurrently.txt",
    projectUrl: "https://github.com/npm/move-concurrently"
  },
  {
    name: "fs-write-stream-atomic",
    version: "1.0.10",
    license: "ISC",
    licenseUrl: "/frontend-license/fs-write-stream-atomic.txt",
    projectUrl: "https://github.com/npm/fs-write-stream-atomic"
  },
  {
    name: "browserify-sign",
    version: "4.0.4",
    license: "ISC",
    licenseUrl: "/frontend-license/browserify-sign.txt",
    projectUrl: "https://github.com/browserify/browserify-sign"
  },
  {
    name: "minimatch",
    version: "3.0.4",
    license: "ISC",
    licenseUrl: "/frontend-license/minimatch.txt",
    projectUrl: "https://github.com/isaacs/minimatch"
  },
  {
    name: "promise-inflight",
    version: "1.0.1",
    license: "ISC",
    licenseUrl: "/frontend-license/promise-inflight.txt",
    projectUrl: "https://github.com/iarna/promise-inflight"
  },
  {
    name: "remove-trailing-separator",
    version: "1.1.0",
    license: "ISC",
    licenseUrl: "/frontend-license/remove-trailing-separator.txt",
    projectUrl: "https://github.com/darsain/remove-trailing-separator"
  },
  {
    name: "rimraf",
    version: "2.6.2",
    license: "ISC",
    licenseUrl: "/frontend-license/rimraf.txt",
    projectUrl: "https://github.com/isaacs/rimraf"
  },
  {
    name: "aproba",
    version: "1.2.0",
    license: "ISC",
    licenseUrl: "/frontend-license/aproba.txt",
    projectUrl: "https://github.com/iarna/aproba"
  },
  {
    name: "copy-concurrently",
    version: "1.0.5",
    license: "ISC",
    licenseUrl: "/frontend-license/copy-concurrently.txt",
    projectUrl: "https://github.com/npm/copy-concurrently"
  },
  {
    name: "anymatch",
    version: "2.0.0",
    license: "ISC",
    licenseUrl: "/frontend-license/anymatch.txt",
    projectUrl: "https://github.com/micromatch/anymatch"
  },
  {
    name: "y18n",
    version: "4.0.0",
    license: "ISC",
    licenseUrl: "/frontend-license/y18n.txt",
    projectUrl: "https://github.com/yargs/y18n"
  },
  {
    name: "minimalistic-assert",
    version: "1.0.1",
    license: "ISC",
    licenseUrl: "/frontend-license/minimalistic-assert.txt",
    projectUrl: "https://github.com/calvinmetcalf/minimalistic-assert"
  },
  {
    name: "ssri",
    version: "6.0.1",
    license: "ISC",
    licenseUrl: "/frontend-license/ssri.txt",
    projectUrl: "https://github.com/npm/ssri"
  },
  {
    name: "figgy-pudding",
    version: "3.5.1",
    license: "ISC",
    licenseUrl: "/frontend-license/figgy-pudding.txt",
    projectUrl: "https://github.com/npm/figgy-pudding"
  },
  {
    name: "unique-filename",
    version: "1.1.1",
    license: "ISC",
    licenseUrl: "/frontend-license/unique-filename.txt",
    projectUrl: "https://github.com/npm/unique-filename"
  },
  {
    name: "lru-cache",
    version: "5.1.1",
    license: "ISC",
    licenseUrl: "/frontend-license/lru-cache.txt",
    projectUrl: "https://github.com/isaacs/node-lru-cache"
  },
  {
    name: "rimraf",
    version: "2.6.3",
    license: "ISC",
    licenseUrl: "/frontend-license/rimraf.txt",
    projectUrl: "https://github.com/isaacs/rimraf"
  },
  {
    name: "infer-owner",
    version: "1.0.4",
    license: "ISC",
    licenseUrl: "/frontend-license/infer-owner.txt",
    projectUrl: "https://github.com/isaacs/infer-owner"
  },
  {
    name: "rimraf",
    version: "2.7.1",
    license: "ISC",
    licenseUrl: "/frontend-license/rimraf.txt",
    projectUrl: "https://github.com/isaacs/rimraf"
  },
  {
    name: "cacache",
    version: "12.0.3",
    license: "ISC",
    licenseUrl: "/frontend-license/cacache.txt",
    projectUrl: "https://github.com/npm/cacache"
  },
  {
    name: "parse-asn1",
    version: "5.1.5",
    license: "ISC",
    licenseUrl: "/frontend-license/parse-asn1.txt",
    projectUrl: "https://github.com/browserify/parse-asn1"
  },
  {
    name: "yallist",
    version: "3.1.1",
    license: "ISC",
    licenseUrl: "/frontend-license/yallist.txt",
    projectUrl: "https://github.com/isaacs/yallist"
  },
  {
    name: "yaml",
    version: "1.7.2",
    license: "ISC",
    licenseUrl: "/frontend-license/yaml.txt",
    projectUrl: "https://github.com/eemeli/yaml"
  },
  {
    name: "graceful-fs",
    version: "4.2.3",
    license: "ISC",
    licenseUrl: "/frontend-license/graceful-fs.txt",
    projectUrl: "https://github.com/isaacs/node-graceful-fs"
  },
  {
    name: "chownr",
    version: "1.1.4",
    license: "ISC",
    licenseUrl: "/frontend-license/chownr.txt",
    projectUrl: "https://github.com/isaacs/chownr"
  },
  {
    name: "Remotion.Linq.EagerFetching",
    version: "2.2.0",
    license: "LGPL 2.1",
    licenseUrl: "/backend-license/RemotionLinqEagerFetching.txt",
    projectUrl: "https://github.com/re-motion/Relinq-EagerFetching/"
  },
  {
    name: "NHibernate",
    version: "5.3.5",
    license: "LGPL 2.1",
    licenseUrl: "/backend-license/NHibernate.txt",
    projectUrl: "https://github.com/nhibernate/nhibernate-core"
  },
  {
    name: "Microsoft.Web.Infrastructure",
    version: "1.0.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftWebInfrastructure.txt",
    projectUrl: "https://www.nuget.org/packages/Microsoft.Web.Infrastructure"
  },
  {
    name: "WebGrease",
    version: "1.6.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/WebGrease.txt",
    projectUrl: "https://www.nuget.org/packages/WebGrease"
  },
  {
    name: "System.Security.Cryptography.X509Certificates",
    version: "4.3.2",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/SystemSecurityCryptographyAlgorithms.txt",
    projectUrl: "https://www.nuget.org/packages/System.Security.Cryptography.Algorithms"
  },
  {
    name: "System.Security.Cryptography.Algorithms",
    version: "4.3.1",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/SystemSecurityCryptographyAlgorithms.txt",
    projectUrl: "https://www.nuget.org/packages/System.Security.Cryptography.Algorithms"
  },
  {
    name: "Microsoft.AspNet.WebApi.Client",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetMvc.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.Razor",
    version: "3.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetMvc.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi.Core",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetMvc.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.Mvc",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetMvc.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebPages",
    version: "3.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebPages.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebApi.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi.WebHost",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebApiWebHost.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.Cors",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetCors.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi.Cors",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebApiCors.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi.Owin",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebApiOwin.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "Microsoft.AspNet.WebApi.HelpPage",
    version: "5.2.7",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebApiHelpPage.txt",
    projectUrl: "https://github.com/aspnet/AspNetWebStack"
  },
  {
    name: "System.Net.Http",
    version: "4.3.4",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/SystemNetHttp.txt",
    projectUrl: "https://www.nuget.org/packages/System.Net.Http"
  },
  {
    name: "System.Runtime",
    version: "4.3.1",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/SystemRuntime.txt",
    projectUrl: "https://www.nuget.org/packages/System.Runtime"
  },
  {
    name: "Microsoft.AspNet.Identity.Core",
    version: "2.2.3",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetIdentityCore.txt",
    projectUrl: "https://github.com/aspnet/AspNetIdentity"
  },
  {
    name: "Microsoft.AspNet.Identity.Owin",
    version: "2.2.3",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetIdentityOwin.txt",
    projectUrl: "https://github.com/aspnet/AspNetIdentity"
  },
  {
    name: "Microsoft.CodeDom.Providers.DotNetCompilerPlatform",
    version: "3.6.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftCodeDomProvidersDotNetCompilerPlatform.txt",
    projectUrl: "https://github.com/aspnet/RoslynCodeDomProvider"
  },
  {
    name: "Microsoft.AspNet.Web.Optimization",
    version: "1.1.3",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/MicrosoftAspNetWebOptimization.txt",
    projectUrl: "https://www.nuget.org/packages/Microsoft.AspNet.Web.Optimization"
  },
  {
    name: "minimist",
    version: "0.0.8",
    license: "MIT",
    licenseUrl: "/frontend-license/minimist.txt",
    projectUrl: "https://github.com/minimistjs/minimist"
  },
  {
    name: "string_decoder",
    version: "0.10.31",
    license: "MIT",
    licenseUrl: "/frontend-license/string_decoder.txt",
    projectUrl: "https://github.com/nodejs/string_decoder"
  },
  {
    name: "punycode",
    version: "1.3.2",
    license: "MIT",
    licenseUrl: "/frontend-license/punycode.txt",
    projectUrl: "https://github.com/mathiasbynens/punycode.js"
  },
  {
    name: "isarray",
    version: "0.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/isarray.txt",
    projectUrl: "https://github.com/juliangruber/isarray"
  },
  {
    name: "concat-map",
    version: "0.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/concat-map.txt",
    projectUrl: "https://github.com/ljharb/concat-map"
  },
  {
    name: "typedarray",
    version: "0.0.6",
    license: "MIT",
    licenseUrl: "/frontend-license/typedarray.txt",
    projectUrl: "https://github.com/es-shims/typedarray"
  },
  {
    name: "util",
    version: "0.10.3",
    license: "MIT",
    licenseUrl: "/frontend-license/util.txt",
    projectUrl: "https://github.com/browserify/node-util"
  },
  {
    name: "tty-browserify",
    version: "0.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/tty-browserify.txt",
    projectUrl: "https://github.com/browserify/tty-browserify"
  },
  {
    name: "querystring-es3",
    version: "0.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/querystring-es3.txt",
    projectUrl: "https://github.com/mike-spainhower/querystring"
  },
  {
    name: "querystring",
    version: "0.2.0",
    license: "MIT",
    licenseUrl: "/backend-license/querystring.txt",
    projectUrl: "https://github.com/WindowsNotifications/QueryString.NET"
  },
  {
    name: "iferr",
    version: "0.1.5",
    license: "MIT",
    licenseUrl: "/frontend-license/iferr.txt",
    projectUrl: "https://github.com/shesek/iferr"
  },
  {
    name: "foreach",
    version: "2.0.5",
    license: "MIT",
    licenseUrl: "/frontend-license/foreach.txt",
    projectUrl: "https://github.com/manuelstofer/foreach"
  },
  {
    name: "void-elements",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/void-elements.txt",
    projectUrl: "https://github.com/pugjs/void-elements"
  },
  {
    name: "imurmurhash",
    version: "0.1.4",
    license: "MIT",
    licenseUrl: "/frontend-license/imurmurhash.txt",
    projectUrl: "https://github.com/jensyt/imurmurhash-js"
  },
  {
    name: "resolve-url",
    version: "0.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve-url.txt",
    projectUrl: "https://github.com/lydell/resolve-url"
  },
  {
    name: "urix",
    version: "0.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/urix.txt",
    projectUrl: "https://github.com/lydell/urix"
  },
  {
    name: "minimist",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/minimist.txt",
    projectUrl: "https://github.com/minimistjs/minimist"
  },
  {
    name: "is-binary-path",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/is-binary-path.txt",
    projectUrl: "https://github.com/sindresorhus/is-binary-path"
  },
  {
    name: "object-assign",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/object-assign.txt",
    projectUrl: "https://github.com/sindresorhus/object-assign"
  },
  {
    name: "util-deprecate",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/util-deprecate.txt",
    projectUrl: "https://github.com/TooTallNate/util-deprecate"
  },
  {
    name: "mkdirp",
    version: "0.5.1",
    license: "MIT",
    licenseUrl: "/frontend-license/mkdirp.txt",
    projectUrl: "https://github.com/isaacs/node-mkdirp"
  },
  {
    name: "is-extendable",
    version: "0.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/is-extendable.txt",
    projectUrl: "https://github.com/jonschlinkert/is-extendable"
  },
  {
    name: "path-to-regexp",
    version: "0.1.7",
    license: "MIT",
    licenseUrl: "/frontend-license/path-to-regexp.txt",
    projectUrl: "https://github.com/pillarjs/path-to-regexp"
  },
  {
    name: "isarray",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/isarray.txt",
    projectUrl: "https://github.com/juliangruber/isarray"
  },
  {
    name: "pify",
    version: "2.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pify.txt",
    projectUrl: "https://github.com/sindresorhus/pify"
  },
  {
    name: "is-arrayish",
    version: "0.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/is-arrayish.txt",
    projectUrl: "https://github.com/qix-/node-is-arrayish"
  },
  {
    name: "path-exists",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend0license/path-exists.txt",
    projectUrl: "https://github.com/sindresorhus/path-exists"
  },
  {
    name: "parse-json",
    version: "2.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/parse-json.txt",
    projectUrl: "https://github.com/sindresorhus/parse-json"
  },
  {
    name: "buffer-xor",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/buffer-xor.txt",
    projectUrl: "https://github.com/browserify/buffer-xor"
  },
  {
    name: "constants-browserify",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/constants-browserify.txt",
    projectUrl: "https://github.com/juliangruber/constants-browserify"
  },
  {
    name: "to-arraybuffer",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/to-arraybuffer.txt",
    projectUrl: "https://github.com/jhiesey/to-arraybuffer"
  },
  {
    name: "url",
    version: "0.11.0",
    license: "MIT",
    licenseUrl: "/frontend-license/url.txt",
    projectUrl: "https://github.com/defunctzombie/node-url"
  },
  {
    name: "encoding",
    version: "0.1.12",
    license: "MIT",
    licenseUrl: "/frontend-license/encoding.txt",
    projectUrl: "https://github.com/andris9/encoding"
  },
  {
    name: "isomorphic-fetch",
    version: "2.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/isomorphic-fetch.txt",
    projectUrl: "https://github.com/matthew-andrews/isomorphic-fetch"
  },
  {
    name: "extend-shallow",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/extend-shallow.txt",
    projectUrl: "https://github.com/jonschlinkert/extend-shallow"
  },
  {
    name: "resolve",
    version: "1.1.7",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve.txt",
    projectUrl: "https://github.com/browserify/resolve"
  },
  {
    name: "escape-string-regexp",
    version: "1.0.5",
    license: "MIT",
    licenseUrl: "/frontend-license/escape-string-regexp.txt",
    projectUrl: "https://github.com/sindresorhus/escape-string-regexp"
  },
  {
    name: "pinkie",
    version: "2.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/pinkie.txt",
    projectUrl: "https://www.npmjs.com/package/pinkie"
  },
  {
    name: "punycode",
    version: "1.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/punycode.txt",
    projectUrl: "https://github.com/mathiasbynens/punycode.js"
  },
  {
    name: "browserify-rsa",
    version: "4.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/browserify-rsa.txt",
    projectUrl: "https://github.com/browserify/browserify-rsa"
  },
  {
    name: "find-up",
    version: "1.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/find-up.txt",
    projectUrl: "https://github.com/sindresorhus/find-up"
  },
  {
    name: "pinkie-promise",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/pinkie-promise.txt",
    projectUrl: "https://github.com/floatdrop/pinkie-promise"
  },
  {
    name: "readable-stream",
    version: "1.0.34",
    license: "MIT",
    licenseUrl: "/frontend-license/readable-stream.txt",
    projectUrl: "https://github.com/nodejs/readable-stream"
  },
  {
    name: "isobject",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/isobject.txt",
    projectUrl: "https://github.com/jonschlinkert/isobject"
  },
  {
    name: "is-stream",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/is-stream.txt",
    projectUrl: "https://github.com/sindresorhus/is-stream"
  },
  {
    name: "component-emitter",
    version: "1.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/component-emitter.txt",
    projectUrl: "https://github.com/sindresorhus/component-emitter"
  },
  {
    name: "commondir",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/commondir.txt",
    projectUrl: "https://github.com/substack/node-commondir"
  },
  {
    name: "source-map-url",
    version: "0.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/source-map-url.txt",
    projectUrl: "https://github.com/lydell/source-map-url"
  },
  {
    name: "core-js",
    version: "1.2.7",
    license: "MIT",
    licenseUrl: "/frontend-license/core-js.txt",
    projectUrl: "https://github.com/zloirock/core-js"
  },
  {
    name: "stream-shift",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/stream-shift.txt",
    projectUrl: "https://github.com/mafintosh/stream-shift"
  },
  {
    name: "map-cache",
    version: "0.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/map-cache.txt",
    projectUrl: "https://github.com/jonschlinkert/map-cache"
  },
  {
    name: "pkg-dir",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pkg-dir.txt",
    projectUrl: "https://github.com/sindresorhus/pkg-dir"
  },
  {
    name: "arr-union",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/arr-union.txt",
    projectUrl: "https://github.com/jonschlinkert/arr-union"
  },
  {
    name: "react-prop-types",
    version: "0.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react-prop-types.txt",
    projectUrl: "https://github.com/react-bootstrap/react-prop-types"
  },
  {
    name: "to-object-path",
    version: "0.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/to-object-path.txt",
    projectUrl: "https://github.com/jonschlinkert/to-object-path"
  },
  {
    name: "set-getter",
    version: "0.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/set-getter.txt",
    projectUrl: "https://github.com/doowb/set-getter"
  },
  {
    name: "has-value",
    version: "0.3.1",
    license: "MIT",
    licenseUrl: "/frontend-license/has-value.txt",
    projectUrl: "https://github.com/jonschlinkert/has-value"
  },
  {
    name: "is-data-descriptor",
    version: "0.1.4",
    license: "MIT",
    licenseUrl: "/frontend-license/is-data-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-data-descriptor"
  },
  {
    name: "object-copy",
    version: "0.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/object-copy.txt",
    projectUrl: "https://github.com/jonschlinkert/object-copy"
  },
  {
    name: "define-property",
    version: "0.2.5",
    license: "MIT",
    licenseUrl: "/frontend-license/define-property.txt",
    projectUrl: "https://github.com/jonschlinkert/define-property"
  },
  {
    name: "is-accessor-descriptor",
    version: "0.1.6",
    license: "MIT",
    licenseUrl: "/frontend-license/is-accessor-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-accessor-descriptor"
  },
  {
    name: "static-extend",
    version: "0.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/static-extend.txt",
    projectUrl: "https://github.com/jonschlinkert/static-extend"
  },
  {
    name: "has-values",
    version: "0.1.4",
    license: "MIT",
    licenseUrl: "/frontend-license/has-values.txt",
    projectUrl: "https://github.com/jonschlinkert/has-values"
  },
  {
    name: "get-value",
    version: "2.0.6",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/jonschlinkert/get-value"
  },
  {
    name: "copy-descriptor",
    version: "0.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/copy-descriptor.txt",
    projectUrl: "https://github.com/jonschlinkert/copy-descriptor"
  },
  {
    name: "number-is-nan",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/number-is-nan.txt",
    projectUrl: "https://github.com/DefinitelyTyped/DefinitelyTyped"
  },
  {
    name: "path-is-absolute",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/path-is-absolute.txt",
    projectUrl: "https://github.com/DefinitelyTyped/DefinitelyTyped"
  },
  {
    name: "setimmediate",
    version: "1.0.5",
    license: "MIT",
    licenseUrl: "/frontend-license/setimmediate.txt",
    projectUrl: "https://github.com/yuzujs/setImmediate"
  },
  {
    name: "emojis-list",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/emojis-list.ttx",
    projectUrl: "https://github.com/kikobeats/emojis-list"
  },
  {
    name: "find-cache-dir",
    version: "0.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/find-cache-dir.txt",
    projectUrl: "https://github.com/sindresorhus/find-cache-dir"
  },
  {
    name: "repeat-string",
    version: "1.6.1",
    license: "MIT",
    licenseUrl: "/frontend-license/repeat-string.txt",
    projectUrl: "https://github.com/jonschlinkert/repeat-string"
  },
  {
    name: "path-dirname",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/path-dirname.txt",
    projectUrl: "https://github.com/gulpjs/path-dirname"
  },
  {
    name: "babel-plugin-syntax-jsx",
    version: "6.18.0",
    license: "MIT",
    licenseUrl: "/frontend-license/babel-plugin-syntax-jsx.txt",
    projectUrl: "https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-jsx"
  },
  {
    name: "safe-regex",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/safe-regex.txt",
    projectUrl: "https://github.com/davisjam/safe-regex"
  },
  {
    name: "path-exists",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend0license/path-exists.txt",
    projectUrl: "https://github.com/sindresorhus/path-exists"
  },
  {
    name: "concat-stream",
    version: "1.6.0",
    license: "MIT",
    licenseUrl: "/frontend-license/concat-stream.txt",
    projectUrl: "https://github.com/max-mapper/concat-stream"
  },
  {
    name: "through2",
    version: "2.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/through2.txt",
    projectUrl: "https://github.com/rvagg/through2"
  },
  {
    name: "json5",
    version: "0.5.1",
    license: "MIT",
    licenseUrl: "/frontend-license/json5.txt",
    projectUrl: "https://github.com/json5/json5"
  },
  {
    name: "memory-fs",
    version: "0.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/memory-fs.txt",
    projectUrl: "https://github.com/webpack/memory-fs"
  },
  {
    name: "builtin-status-codes",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/builtin-status-codes.txt",
    projectUrl: "https://github.com/bendrucker/builtin-status-codes"
  },
  {
    name: "date-arithmetic",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/date-arithmetic.txt",
    projectUrl: "https://github.com/jquense/date-math"
  },
  {
    name: "lazy-cache",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/lazy-cache.txt",
    projectUrl: "https://github.com/jonschlinkert/lazy-cache"
  },
  {
    name: "lodash",
    version: "4.17.4",
    license: "MIT",
    licenseUrl: "/frontend-license/lodash.txt",
    projectUrl: "https://github.com/lodash/lodash"
  },
  {
    name: "loose-envify",
    version: "1.3.1",
    license: "MIT",
    licenseUrl: "/frontend-license/loose-envify.txt",
    projectUrl: "https://github.com/zertosh/loose-envify"
  },
  {
    name: "object-assign",
    version: "4.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/object-assign.txt",
    projectUrl: "https://github.com/sindresorhus/object-assign"
  },
  {
    name: "has-flag",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/has-flag.txt",
    projectUrl: "https://github.com/sindresorhus/has-flag"
  },
  {
    name: "loader-utils",
    version: "0.2.17",
    license: "MIT",
    licenseUrl: "/frontend-license/loader-utils.txt",
    projectUrl: "https://github.com/webpack/loader-utils"
  },
  {
    name: "brorand",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/brorand.txt",
    projectUrl: "https://github.com/indutny/brorand"
  },
  {
    name: "minimalistic-crypto-utils",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/minimalistic-crypto-utils.txt",
    projectUrl: "https://github.com/indutny/minimalistic-crypto-utils"
  },
  {
    name: "set-value",
    version: "0.4.3",
    license: "MIT",
    licenseUrl: "/frontend-license/set-value.txt",
    projectUrl: "https://github.com/jonschlinkert/set-value"
  },
  {
    name: "for-in",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/for-in.txt",
    projectUrl: "https://github.com/jonschlinkert/for-in"
  },
  {
    name: "Respond",
    version: "1.4.2",
    license: "MIT",
    licenseUrl: "/backend-license/Respond.txt",
    projectUrl: "https://github.com/scottjehl/Respond"
  },
  {
    name: "from2",
    version: "2.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/from2.txt",
    projectUrl: "https://github.com/hughsk/from2"
  },
  {
    name: "cyclist",
    version: "0.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/cyclist.txt",
    projectUrl: "https://github.com/mafintosh/cyclist"
  },
  {
    name: "parallel-transform",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/parallel-transform.txt",
    projectUrl: "https://github.com/mafintosh/parallel-transform"
  },
  {
    name: "path-type",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/path-type.txt",
    projectUrl: "https://github.com/sindresorhus/path-type"
  },
  {
    name: "locate-path",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/locate-path.txt",
    projectUrl: "https://github.com/sindresorhus/locate-path"
  },
  {
    name: "find-up",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/find-up.txt",
    projectUrl: "https://github.com/sindresorhus/find-up"
  },
  {
    name: "p-locate",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/p-locate.txt",
    projectUrl: "https://github.com/sindresorhus/p-locate"
  },
  {
    name: "normalize-path",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/normalize-path.txt",
    projectUrl: "https://github.com/jonschlinkert/normalize-path"
  },
  {
    name: "pascalcase",
    version: "0.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/pascalcase.txt",
    projectUrl: "https://github.com/jonschlinkert/pascalcase"
  },
  {
    name: "process",
    version: "0.11.10",
    license: "MIT",
    licenseUrl: "/frontend-license/process.txt",
    projectUrl: "https://github.com/defunctzombie/node-process"
  },
  {
    name: "uncontrollable",
    version: "4.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/uncontrollable.txt",
    projectUrl: "https://github.com/jquense/uncontrollable"
  },
  {
    name: "ms",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/ms.txt",
    projectUrl: "https://github.com/vercel/ms"
  },
  {
    name: "kind-of",
    version: "3.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/kind-of.txt",
    projectUrl: "https://github.com/jonschlinkert/kind-of"
  },
  {
    name: "https-browserify",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/https-browserify.txt",
    projectUrl: "https://www.npmjs.com/package/https-browserify"
  },
  {
    name: "kind-of",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/kind-of.txt",
    projectUrl: "https://github.com/jonschlinkert/kind-of"
  },
  {
    name: "is-number",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/is-number",
    projectUrl: "https://github.com/jonschlinkert/is-number"
  },
  {
    name: "is-wsl",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/is-wsl.txt",
    projectUrl: "https://github.com/sindresorhus/is-wsl"
  },
  {
    name: "pify",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pify.txt",
    projectUrl: "https://github.com/sindresorhus/pify"
  },
  {
    name: "pkg-dir",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pkg-dir.txt",
    projectUrl: "https://github.com/sindresorhus/pkg-dir"
  },
  {
    name: "resolve-from",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve-from.txt",
    projectUrl: "https://github.com/sindresorhus/resolve-from"
  },
  {
    name: "prr",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/prr.txt",
    projectUrl: "https://github.com/rvagg/archived-prr"
  },
  {
    name: "find-root",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/find-root.txt",
    projectUrl: "https://github.com/junosuarez/find-root"
  },
  {
    name: "js-tokens",
    version: "3.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/js-tokens.txt",
    projectUrl: "https://github.com/lydell/js-tokens"
  },
  {
    name: "arr-flatten",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/arr-flatten",
    projectUrl: "https://github.com/jonschlinkert/arr-flatten"
  },
  {
    name: "balanced-match",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/balanced-match.txt",
    projectUrl: "https://github.com/juliangruber/balanced-match"
  },
  {
    name: "asap",
    version: "2.0.6",
    license: "MIT",
    licenseUrl: "/frontend-license/asap.txt",
    projectUrl: "https://github.com/kriskowal/asap"
  },
  {
    name: "promise",
    version: "7.3.1",
    license: "MIT",
    licenseUrl: "/frontend-license/promise.txt",
    projectUrl: "https://github.com/then/promise"
  },
  {
    name: "is-plain-object",
    version: "2.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/is-plain-object.txt",
    projectUrl: "https://github.com/jonschlinkert/is-plain-object"
  },
  {
    name: "color-name",
    version: "1.1.3",
    license: "MIT",
    licenseUrl: "/frontend-license/color-name.txt",
    projectUrl: "https://github.com/colorjs/color-name"
  },
  {
    name: "cipher-base",
    version: "1.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/cipher-base.txt",
    projectUrl: "https://github.com/browserify/cipher-base"
  },
  {
    name: "isobject",
    version: "3.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/isobject.txt",
    projectUrl: "https://github.com/jonschlinkert/isobject"
  },
  {
    name: "hmac-drbg",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/hmac-drbg.txt",
    projectUrl: "https://github.com/indutny/hmac-drbg"
  },
  {
    name: "ansi-styles",
    version: "3.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/ansi-styles.txt",
    projectUrl: "https://github.com/chalk/ansi-styles"
  },
  {
    name: "bn.js",
    version: "4.11.8",
    license: "MIT",
    licenseUrl: "/frontend-license/bnjs.txt",
    projectUrl: "https://github.com/indutny/bn.js"
  },
  {
    name: "hash-base",
    version: "3.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/hash-base.txt",
    projectUrl: "https://github.com/browserify/hash-base"
  },
  {
    name: "object.pick",
    version: "1.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/objectpick.txt",
    projectUrl: "https://github.com/jonschlinkert/object.pick"
  },
  {
    name: "regex-not",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/regex-not.txt",
    projectUrl: "https://github.com/jonschlinkert/regex-not"
  },
  {
    name: "define-property",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/define-property.txt",
    projectUrl: "https://github.com/jonschlinkert/define-property"
  },
  {
    name: "to-regex-range",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/to-regex-range.txt",
    projectUrl: "https://github.com/micromatch/to-regex-range"
  },
  {
    name: "snapdragon-node",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/snapdragon-node.txt",
    projectUrl: "https://github.com/here-be/snapdragon-node"
  },
  {
    name: "to-regex",
    version: "3.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/to-regex.txt",
    projectUrl: "https://github.com/jonschlinkert/to-regex"
  },
  {
    name: "posix-character-classNamees",
    version: "0.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/posix-character-classNamees.txt",
    projectUrl: "https://github.com/micromatch/posix-character-classes"
  },
  {
    name: "expand-brackets",
    version: "2.1.4",
    license: "MIT",
    licenseUrl: "/frontend-license/expand-brackets.txt",
    projectUrl: "https://github.com/micromatch/expand-brackets"
  },
  {
    name: "snapdragon",
    version: "0.8.1",
    license: "MIT",
    licenseUrl: "/frontend-license/snapdragon.txt",
    projectUrl: "https://github.com/here-be/snapdragon"
  },
  {
    name: "fragment-cache",
    version: "0.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/fragment-cache.txt",
    projectUrl: "https://github.com/jonschlinkert/fragment-cache"
  },
  {
    name: "array-unique",
    version: "0.3.2",
    license: "MIT",
    licenseUrl: "/frontend-license/array-unique.txt",
    projectUrl: "https://github.com/jonschlinkert/array-unique"
  },
  {
    name: "use",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/use.txt",
    projectUrl: "https://github.com/jonschlinkert/use"
  },
  {
    name: "arr-diff",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/arr-diff.txt",
    projectUrl: "https://github.com/jonschlinkert/arr-diff"
  },
  {
    name: "fill-range",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/fill-range.txt",
    projectUrl: "https://github.com/jonschlinkert/fill-range"
  },
  {
    name: "snapdragon-util",
    version: "3.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/snapdragon-util.txt",
    projectUrl: "https://github.com/here-be/snapdragon-util"
  },
  {
    name: "evp_bytestokey",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/evp_bytestokey.txt",
    projectUrl: "https://github.com/browserify/EVP_BytesToKey"
  },
  {
    name: "map-visit",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/map-visit.txt",
    projectUrl: "https://github.com/jonschlinkert/map-visit"
  },
  {
    name: "union-value",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/union-value.txt",
    projectUrl: "https://github.com/jonschlinkert/union-value"
  },
  {
    name: "has-value",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/has-value.txt",
    projectUrl: "https://github.com/jonschlinkert/has-value"
  },
  {
    name: "has-values",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/has-values.txt",
    projectUrl: "https://github.com/jonschlinkert/has-values"
  },
  {
    name: "object-visit",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/object-visit.txt",
    projectUrl: "https://github.com/jonschlinkert/object-visit"
  },
  {
    name: "set-value",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/set-value.txt",
    projectUrl: "https://github.com/jonschlinkert/set-value"
  },
  {
    name: "is-descriptor",
    version: "0.1.6",
    license: "MIT",
    licenseUrl: "/frontend-license/is-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-descriptor"
  },
  {
    name: "cache-base",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/cache-base.txt",
    projectUrl: "https://github.com/jonschlinkert/cache-base"
  },
  {
    name: "collection-visit",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/collection-visit.txt",
    projectUrl: "https://github.com/jonschlinkert/collection-visit"
  },
  {
    name: "unset-value",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/unset-value.txt",
    projectUrl: "https://github.com/jonschlinkert/unset-value"
  },
  {
    name: "decode-uri-component",
    version: "0.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/decode-uri-component.txt",
    projectUrl: "https://github.com/SamVerschueren/decode-uri-component"
  },
  {
    name: "node-fetch",
    version: "1.7.3",
    license: "MIT",
    licenseUrl: "/frontend-license/node-fetch.txt",
    projectUrl: "https://github.com/node-fetch/node-fetch"
  },
  {
    name: "base",
    version: "0.11.2",
    license: "MIT",
    licenseUrl: "/frontend-license/base.txt",
    projectUrl: "https://github.com/base/base"
  },
  {
    name: "big.js",
    version: "3.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/bigjs.txt",
    projectUrl: "https://github.com/MikeMcl/big.js"
  },
  {
    name: "miller-rabin",
    version: "4.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/miller-rabin.txt",
    projectUrl: "https://github.com/indutny/miller-rabin"
  },
  {
    name: "to-fast-properties",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/to-fast-properties.txt",
    projectUrl: "https://github.com/sindresorhus/to-fast-properties"
  },
  {
    name: "path-type",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/path-type.txt",
    projectUrl: "https://github.com/sindresorhus/path-type"
  },
  {
    name: "kind-of",
    version: "5.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/kind-of.txt",
    projectUrl: "https://github.com/jonschlinkert/kind-of"
  },
  {
    name: "chalk",
    version: "2.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/chalk.txt",
    projectUrl: "https://github.com/chalk/chalk"
  },
  {
    name: "os-browserify",
    version: "0.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/os-browserify.txt",
    projectUrl: "https://github.com/CoderPuppy/os-browserify"
  },
  {
    name: "browserify-zlib",
    version: "0.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/browserify-zlib.txt",
    projectUrl: "https://github.com/browserify/browserify-zlib"
  },
  {
    name: "is-buffer",
    version: "1.1.6",
    license: "MIT",
    licenseUrl: "/frontend-license/is-buffer.txt",
    projectUrl: "https://github.com/feross/is-buffer"
  },
  {
    name: "react-overlays",
    version: "0.7.4",
    license: "MIT",
    licenseUrl: "/frontend-license/react-overlays.txt",
    projectUrl: "https://github.com/react-bootstrap/react-overlays"
  },
  {
    name: "crypto-browserify",
    version: "3.12.0",
    license: "MIT",
    licenseUrl: "/frontend-license/crypto-browserify.txt",
    projectUrl: "https://github.com/browserify/crypto-browserify"
  },
  {
    name: "color-convert",
    version: "1.9.1",
    license: "MIT",
    licenseUrl: "/frontend-license/color-convert.txt",
    projectUrl: "https://github.com/Qix-/color-convert"
  },
  {
    name: "p-try",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/p-try.txt",
    projectUrl: "https://github.com/sindresorhus/p-try"
  },
  {
    name: "html-parse-stringify2",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/html-parse-stringify2.txt",
    projectUrl: "https://github.com/rayd/html-parse-stringify2"
  },
  {
    name: "convert-source-map",
    version: "1.5.1",
    license: "MIT",
    licenseUrl: "/frontend-license/convert-source-map.txt",
    projectUrl: "https://github.com/thlorenz/convert-source-map"
  },
  {
    name: "split-string",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/split-string.txt",
    projectUrl: "https://github.com/jonschlinkert/split-string"
  },
  {
    name: "ms",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/ms.txt",
    projectUrl: "https://github.com/vercel/ms"
  },
  {
    name: "debug",
    version: "2.6.9",
    license: "MIT",
    licenseUrl: "/frontend-license/debug.txt",
    projectUrl: "https://github.com/debug-js/debug"
  },
  {
    name: "parse-json",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/parse-json.txt",
    projectUrl: "https://github.com/sindresorhus/parse-json"
  },
  {
    name: "resolve-from",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve-from.txt",
    projectUrl: "https://github.com/sindresorhus/resolve-from"
  },
  {
    name: "kind-of",
    version: "6.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/kind-of.txt",
    projectUrl: "https://github.com/jonschlinkert/kind-of"
  },
  {
    name: "is-extendable",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/is-extendable.txt",
    projectUrl: "https://github.com/jonschlinkert/is-extendable"
  },
  {
    name: "mixin-deep",
    version: "1.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/mixin-deep.txt",
    projectUrl: "https://github.com/jonschlinkert/mixin-deep"
  },
  {
    name: "assign-symbols",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/assign-symbols.txt",
    projectUrl: "https://github.com/jonschlinkert/assign-symbols"
  },
  {
    name: "extend-shallow",
    version: "3.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/extend-shallow.txt",
    projectUrl: "https://github.com/jonschlinkert/extend-shallow"
  },
  {
    name: "dom-helpers",
    version: "3.3.1",
    license: "MIT",
    licenseUrl: "/frontend-license/dom-helpers.txt",
    projectUrl: "https://github.com/react-bootstrap/dom-helpers"
  },
  {
    name: "is-data-descriptor",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/is-data-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-data-descriptor"
  },
  {
    name: "is-accessor-descriptor",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/is-accessor-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-accessor-descriptor"
  },
  {
    name: "is-descriptor",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/is-descriptor.txt",
    projectUrl: "https://github.com/inspect-js/is-descriptor"
  },
  {
    name: "end-of-stream",
    version: "1.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/",
    projectUrl: "https://github.com/mafintosh/end-of-stream"
  },
  {
    name: "toastr",
    version: "2.1.4",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "pump",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/pump.txt",
    projectUrl: "https://github.com/mafintosh/pump"
  },
  {
    name: "domain-browser",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/domain-browser.txt",
    projectUrl: "https://github.com/bevry/domain-browser"
  },
  {
    name: "brace-expansion",
    version: "1.1.11",
    license: "MIT",
    licenseUrl: "/frontend-license/brace-expansion.txt",
    projectUrl: "https://github.com/juliangruber/brace-expansion"
  },
  {
    name: "has-flag",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/has-flag.txt",
    projectUrl: "https://github.com/sindresorhus/has-flag"
  },
  {
    name: "asn1.js",
    version: "4.10.1",
    license: "MIT",
    licenseUrl: "/frontend-license/asn1js.txt",
    projectUrl: "https://github.com/indutny/asn1.js"
  },
  {
    name: "is-windows",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/is-windows.txt",
    projectUrl: "https://github.com/jonschlinkert/is-windows"
  },
  {
    name: "errno",
    version: "0.1.7",
    license: "MIT",
    licenseUrl: "/frontend-license/errno.txt",
    projectUrl: "https://github.com/rvagg/node-errno"
  },
  {
    name: "randomfill",
    version: "1.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/browserify/randomfill"
  },
  {
    name: "define-property",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/define-property.txt",
    projectUrl: "https://github.com/jonschlinkert/define-property"
  },
  {
    name: "regex-not",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/regex-not.txt",
    projectUrl: "https://github.com/jonschlinkert/regex-not"
  },
  {
    name: "to-regex",
    version: "3.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/to-regex.txt",
    projectUrl: "https://github.com/jonschlinkert/to-regex"
  },
  {
    name: "tapable",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/tapable.txt",
    projectUrl: "https://github.com/webpack/tapable"
  },
  {
    name: "pump",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pump.txt",
    projectUrl: "https://github.com/mafintosh/pump"
  },
  {
    name: "ansi-styles",
    version: "3.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/ansi-styles.txt",
    projectUrl: "https://github.com/chalk/ansi-styles"
  },
  {
    name: "supports-color",
    version: "4.5.0",
    license: "MIT",
    licenseUrl: "/frontend-license/supports-color.txt",
    projectUrl: "https://github.com/chalk/supports-color"
  },
  {
    name: "invariant",
    version: "2.2.4",
    license: "MIT",
    licenseUrl: "/frontend-license/invariant.txt",
    projectUrl: "https://github.com/zertosh/invariant"
  },
  {
    name: "debug",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/debug.txt",
    projectUrl: "https://github.com/debug-js/debug"
  },
  {
    name: "sha.js",
    version: "2.4.11",
    license: "MIT",
    licenseUrl: "/frontend-license/shajs.txt",
    projectUrl: "https://github.com/browserify/sha.js"
  },
  {
    name: "flush-write-stream",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/flush-write-stream.txt",
    projectUrl: "https://github.com/mafintosh/flush-write-stream"
  },
  {
    name: "keycode",
    version: "2.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/keycode.txt",
    projectUrl: "https://github.com/timoxley/keycode"
  },
  {
    name: "string_decoder",
    version: "1.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/string_decoder.txt",
    projectUrl: "https://github.com/nodejs/string_decoder"
  },
  {
    name: "json-parse-better-errors",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/json-parse-better-errors.txt",
    projectUrl: "https://github.com/zkat/json-parse-better-errors"
  },
  {
    name: "safer-buffer",
    version: "2.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/safer-buffer.txt",
    projectUrl: "https://github.com/ChALkeR/safer-buffer"
  },
  {
    name: "braces",
    version: "2.3.2",
    license: "MIT",
    licenseUrl: "/frontend-license/braces.txt",
    projectUrl: "https://github.com/micromatch/braces"
  },
  {
    name: "jquery",
    version: "3.3.1",
    license: "MIT",
    licenseUrl: "/backend-license/jQuery.txt",
    projectUrl: "https://www.nuget.org/packages/jQuery"
  },
  {
    name: "micromatch",
    version: "3.1.10",
    license: "MIT",
    licenseUrl: "/frontend-license/micromatch.txt",
    projectUrl: "https://github.com/micromatch/micromatch"
  },
  {
    name: "browserify-aes",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/browserify-aes.txt",
    projectUrl: "https://github.com/browserify/browserify-aes"
  },
  {
    name: "create-hash",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/create-hash.txt",
    projectUrl: "https://github.com/browserify/createHash"
  },
  {
    name: "create-hmac",
    version: "1.1.7",
    license: "MIT",
    licenseUrl: "/frontend-license/create-hmac.txt",
    projectUrl: "https://github.com/browserify/createHmac"
  },
  {
    name: "diffie-hellman",
    version: "5.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/diffie-hellman.txt",
    projectUrl: "https://github.com/browserify/diffie-hellman"
  },
  {
    name: "browserify-cipher",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/browserify-cipher.txt",
    projectUrl: "https://github.com/browserify/browserify-cipher"
  },
  {
    name: "ripemd160",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/ripemd160.txt",
    projectUrl: "https://github.com/browserify/ripemd160"
  },
  {
    name: "safe-buffer",
    version: "5.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/safe-buffer.txt",
    projectUrl: "https://github.com/feross/safe-buffer"
  },
  {
    name: "watchpack",
    version: "1.6.0",
    license: "MIT",
    licenseUrl: "/frontend-license/watchpack.txt",
    projectUrl: "https://github.com/webpack/watchpack"
  },
  {
    name: "prop-types-extra",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/prop-types-extra.txt",
    projectUrl: "https://github.com/react-bootstrap/prop-types-extra"
  },
  {
    name: "duplexify",
    version: "3.6.0",
    license: "MIT",
    licenseUrl: "/frontend-license/duplexify.txt",
    projectUrl: "https://github.com/mafintosh/duplexify"
  },
  {
    name: "json5",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/json5.txt",
    projectUrl: "https://github.com/json5/json5"
  },
  {
    name: "create-ecdh",
    version: "4.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/create-ecdh.txt",
    projectUrl: "https://github.com/browserify/createECDH"
  },
  {
    name: "react-lifecycles-compat",
    version: "3.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/react-lifecycles-compat.txt",
    projectUrl: "https://github.com/reactjs/react-lifecycles-compat"
  },
  {
    name: "pumpify",
    version: "1.5.1",
    license: "MIT",
    licenseUrl: "/frontend-license/pumpify.txt",
    projectUrl: "https://github.com/mafintosh/pumpify"
  },
  {
    name: "punycode",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/punycode.txt",
    projectUrl: "https://github.com/mathiasbynens/punycode.js"
  },
  {
    name: "stream-http",
    version: "2.8.3",
    license: "MIT",
    licenseUrl: "/frontend-license/stream-http.txt",
    projectUrl: "https://github.com/jhiesey/stream-http"
  },
  {
    name: "p-limit",
    version: "1.3.0",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/sindresorhus/p-limit"
  },
  {
    name: "mamacro",
    version: "0.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/mamacro.txt",
    projectUrl: "https://www.npmjs.com/package/mamacro"
  },
  {
    name: "json-schema-traverse",
    version: "0.4.1",
    license: "MIT",
    licenseUrl: "/frontend-licensejson-schema-traverse.txt",
    projectUrl: "https://github.com/epoberezkin/json-schema-traverse"
  },
  {
    name: "fbjs",
    version: "0.8.17",
    license: "MIT",
    licenseUrl: "/frontend-license/fbjs.txt",
    projectUrl: "https://github.com/facebook/fbjs"
  },
  {
    name: "error-ex",
    version: "1.3.2",
    license: "MIT",
    licenseUrl: "/frontend-license/error-ex.txt",
    projectUrl: "https://github.com/qix-/node-error-ex"
  },
  {
    name: "path-browserify",
    version: "0.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/path-browserify.txt",
    projectUrl: "https://github.com/browserify/path-browserify"
  },
  {
    name: "nanomatch",
    version: "1.2.13",
    license: "MIT",
    licenseUrl: "/frontend-license/nanomatch.txt",
    projectUrl: "https://github.com/micromatch/nanomatch"
  },
  {
    name: "enhanced-resolve",
    version: "4.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/enhanced-resolve.txt",
    projectUrl: "https://github.com/webpack/enhanced-resolve"
  },
  {
    name: "locate-path",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/locate-path.txt",
    projectUrl: "https://github.com/sindresorhus/locate-path"
  },
  {
    name: "p-locate",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/p-locate.txt",
    projectUrl: "https://github.com/sindresorhus/p-locate"
  },
  {
    name: "find-up",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/find-up.txt",
    projectUrl: "https://github.com/sindresorhus/find-up"
  },
  {
    name: "js-tokens",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/js-tokens.txt",
    projectUrl: "https://github.com/lydell/js-tokens"
  },
  {
    name: "loose-envify",
    version: "1.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/loose-envify.txt",
    projectUrl: "https://github.com/zertosh/loose-envify"
  },
  {
    name: "browserify-des",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/browserify-des.txt",
    projectUrl: "https://github.com/browserify/browserify-des"
  },
  {
    name: "pkg-dir",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/pkg-dir.txt",
    projectUrl: "https://github.com/sindresorhus/pkg-dir"
  },
  {
    name: "stream-each",
    version: "1.2.3",
    license: "MIT",
    licenseUrl: "/frontend-license/stream-each.txt",
    projectUrl: "https://github.com/mafintosh/stream-each"
  },
  {
    name: "buffer-from",
    version: "1.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/buffer-from.txt",
    projectUrl: "https://github.com/LinusU/buffer-from"
  },
  {
    name: "react-is",
    version: "16.4.2",
    license: "MIT",
    licenseUrl: "/frontend-license/react-is.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "path-parse",
    version: "1.0.6",
    license: "MIT",
    licenseUrl: "/frontend-license/path-parse.txt",
    projectUrl: "https://github.com/jamiebuilds/gud"
  },
  {
    name: "commander",
    version: "2.17.1",
    license: "MIT",
    licenseUrl: "/frontend-license/commander.txt",
    projectUrl: "https://github.com/tj/commander.js"
  },
  {
    name: "schema-utils",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/schema-utils.txt",
    projectUrl: "https://github.com/webpack/schema-utils"
  },
  {
    name: "gud",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/gud.txt",
    projectUrl: "https://github.com/jamiebuilds/gud"
  },
  {
    name: "repeat-element",
    version: "1.1.3",
    license: "MIT",
    licenseUrl: "/frontend-license/repeat-element.txt",
    projectUrl: "https://github.com/jonschlinkert/repeat-element"
  },
  {
    name: "supports-color",
    version: "5.5.0",
    license: "MIT",
    licenseUrl: "/frontend-license/supports-color.txt",
    projectUrl: "https://github.com/chalk/supports-color"
  },
  {
    name: "iconv-lite",
    version: "0.4.24",
    license: "MIT",
    licenseUrl: "/frontend-license/iconv-lite.txt",
    projectUrl: "https://github.com/ashtuchkin/iconv-lite"
  },
  {
    name: "normalize-path",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/normalize-path.txt",
    projectUrl: "https://github.com/jonschlinkert/normalize-path"
  },
  {
    name: "whatwg-fetch",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/whatwg-fetch.txt",
    projectUrl: "https://github.com/JakeChampion/fetch"
  },
  {
    name: "acorn",
    version: "5.7.3",
    license: "MIT",
    licenseUrl: "/frontend-license/acorn.txt",
    projectUrl: "https://github.com/acornjs/acorn"
  },
  {
    name: "lines-and-columns",
    version: "1.1.6",
    license: "MIT",
    licenseUrl: "/frontend-license/lines-and-columns.txt",
    projectUrl: "https://github.com/eventualbuddha/lines-and-columns"
  },
  {
    name: "readdirp",
    version: "2.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/readdirp.txt",
    projectUrl: "https://github.com/paulmillr/readdirp"
  },
  {
    name: "pbkdf2",
    version: "3.0.17",
    license: "MIT",
    licenseUrl: "/frontend-license/pbkdf2.txt",
    projectUrl: "https://github.com/browserify/pbkdf2"
  },
  {
    name: "public-encrypt",
    version: "4.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/public-encrypt.txt",
    projectUrl: "https://github.com/browserify/publicEncrypt"
  },
  {
    name: "md5.js",
    version: "1.3.5",
    license: "MIT",
    licenseUrl: "/frontend-license/md5js.txt",
    projectUrl: "https://github.com/browserify/md5.js"
  },
  {
    name: "debug",
    version: "3.2.6",
    license: "MIT",
    licenseUrl: "/frontend-license/debug.txt",
    projectUrl: "https://github.com/debug-js/debug"
  },
  {
    name: "source-list-map",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/source-list-map.txt",
    projectUrl: "https://github.com/webpack/source-list-map"
  },
  {
    name: "big.js",
    version: "5.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/bigjs.txt",
    projectUrl: "https://github.com/MikeMcl/big.js"
  },
  {
    name: "pify",
    version: "4.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/pify.txt",
    projectUrl: "https://github.com/sindresorhus/pify"
  },
  {
    name: "util",
    version: "0.11.1",
    license: "MIT",
    licenseUrl: "/frontend-license/util.txt",
    projectUrl: "https://github.com/browserify/node-util"
  },
  {
    name: "dom-helpers",
    version: "3.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/dom-helpers.txt",
    projectUrl: "https://github.com/react-bootstrap/dom-helpers"
  },
  {
    name: "follow-redirects",
    version: "1.5.10",
    license: "MIT",
    licenseUrl: "/frontend-license/follow-redirects.txt",
    projectUrl: "https://github.com/follow-redirects/follow-redirects"
  },
  {
    name: "hash.js",
    version: "1.1.7",
    license: "MIT",
    licenseUrl: "/frontend-license/hashjs.txt",
    projectUrl: "https://github.com/indutny/hash.js"
  },
  {
    name: "react-datetime",
    version: "2.16.3",
    license: "MIT",
    licenseUrl: "/frontend-license/react-datetime.txt",
    projectUrl: "https://github.com/arqex/react-datetime"
  },
  {
    name: "react-big-calendar",
    version: "0.19.2",
    license: "MIT",
    licenseUrl: "/frontend-license/react-big-calendar.txt",
    projectUrl: "https://github.com/jquense/react-big-calendar"
  },
  {
    name: "ajv-errors",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/ajv-errors.txt",
    projectUrl: "https://github.com/ajv-validator/ajv-errors"
  },
  {
    name: "debug",
    version: "4.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/debug.txt",
    projectUrl: "https://github.com/debug-js/debug"
  },
  {
    name: "loader-utils",
    version: "1.2.3",
    license: "MIT",
    licenseUrl: "/frontend-license/loader-utils.txt",
    projectUrl: "https://github.com/webpack/loader-utils"
  },
  {
    name: "Modernizr",
    version: "2.8.3",
    license: "MIT",
    licenseUrl: "/backend-license/Modernizr.txt",
    projectUrl: "https://github.com/Modernizr/Modernizr"
  },
  {
    name: "chalk",
    version: "2.4.2",
    license: "MIT",
    licenseUrl: "/frontend-license/chalk.txt",
    projectUrl: "https://github.com/chalk/chalk"
  },
  {
    name: "supports-color",
    version: "6.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/supports-color.txt",
    projectUrl: "https://github.com/chalk/supports-color"
  },
  {
    name: "loader-runner",
    version: "2.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/loader-runner.txt",
    projectUrl: "https://github.com/webpack/loader-runner"
  },
  {
    name: "moment",
    version: "2.24.0",
    license: "MIT",
    licenseUrl: "/frontend-license/moment.txt",
    projectUrl: "https://github.com/moment/moment"
  },
  {
    name: "stream-browserify",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/stream-browserify.txt",
    projectUrl: "https://github.com/browserify/stream-browserify"
  },
  {
    name: "prop-types",
    version: "15.7.2",
    license: "MIT",
    licenseUrl: "/frontend-license/prop-types.txt",
    projectUrl: "https://github.com/facebook/prop-types"
  },
  {
    name: "randombytes",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/randombytes.txt",
    projectUrl: "https://github.com/browserify/randombytes"
  },
  {
    name: "@webassemblyjs/utf8",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-buffer",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wasm-edit",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-api-error",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/floating-point-hex-parser",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-wasm-bytecode",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wasm-parser",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wasm-gen",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-gen.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wast-printer",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswast-printer.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/ieee754",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjsieee754.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wasm-opt",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswasm-opt.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-fsm",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjshelper-fsm.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-wasm-section",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjshelper-wasm-section.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/ast",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjsast.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-module-context",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjshelper-module-context.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/wast-parser",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjswast-parser.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "@webassemblyjs/helper-code-frame",
    version: "1.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@webassemblyjshelper-code-frame.txt",
    projectUrl: "https://github.com/xtuc/webassemblyjs"
  },
  {
    name: "find-cache-dir",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/find-cache-dir.txt",
    projectUrl: "https://github.com/sindresorhus/find-cache-dir"
  },
  {
    name: "binary-extensions",
    version: "1.13.1",
    license: "MIT",
    licenseUrl: "/frontend-license/binary-extensions.txt",
    projectUrl: "https://github.com/sindresorhus/binary-extensions"
  },
  {
    name: "react-i18next",
    version: "9.0.10",
    license: "MIT",
    licenseUrl: "/frontend-license/react-i18next.txt",
    projectUrl: "https://github.com/i18next/react-i18next"
  },
  {
    name: "parent-module",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/parent-module.txt",
    projectUrl: "https://github.com/sindresorhus/parent-module"
  },
  {
    name: "p-try",
    version: "2.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/p-try.txt",
    projectUrl: "https://github.com/sindresorhus/p-try"
  },
  {
    name: "callsites",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/callsites.txt",
    projectUrl: "https://github.com/sindresorhus/callsites"
  },
  {
    name: "tapable",
    version: "1.1.3",
    license: "MIT",
    licenseUrl: "/frontend-license/tapable.txt",
    projectUrl: "https://github.com/webpack/tapable"
  },
  {
    name: "async-each",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/async-each.txt",
    projectUrl: "https://github.com/paulmillr/async-each"
  },
  {
    name: "worker-farm",
    version: "1.7.0",
    license: "MIT",
    licenseUrl: "/frontend-license/worker-farm.txt",
    projectUrl: "https://github.com/rvagg/node-worker-farm"
  },
  {
    name: "assert",
    version: "1.5.0",
    license: "MIT",
    licenseUrl: "/frontend-license/assert.txt",
    projectUrl: "https://github.com/browserify/commonjs-assert"
  },
  {
    name: "bootstrap",
    version: "3.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/bootstrap.txt",
    projectUrl: "https://github.com/twbs/bootstrap"
  },
  {
    name: "neo-async",
    version: "2.6.1",
    license: "MIT",
    licenseUrl: "/frontend-license/neo-async.txt",
    projectUrl: "https://github.com/suguru03/neo-async"
  },
  {
    name: "chrome-trace-event",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/chrome-trace-event.txt",
    projectUrl: "https://github.com/samccone/chrome-trace-event"
  },
  {
    name: "axios",
    version: "0.18.1",
    license: "MIT",
    licenseUrl: "/frontend-license/axios.txt",
    projectUrl: "https://github.com/axios/axios"
  },
  {
    name: "mini-create-react-context",
    version: "0.3.2",
    license: "MIT",
    licenseUrl: "/frontend-license/mini-create-react-context.txt",
    projectUrl: "https://github.com/StringEpsilon/mini-create-react-context"
  },
  {
    name: "ms",
    version: "2.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/ms.txt",
    projectUrl: "https://github.com/vercel/ms"
  },
  {
    name: "node-libs-browser",
    version: "2.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/node-libs-browser.txt",
    projectUrl: "https://github.com/webpack/node-libs-browser"
  },
  {
    name: "process-nextick-args",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/process-nextick-args.txt",
    projectUrl: "https://github.com/calvinmetcalf/process-nextick-args"
  },
  {
    name: "path-type",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/path-type.txt",
    projectUrl: "https://github.com/sindresorhus/path-type"
  },
  {
    name: "ajv-keywords",
    version: "3.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/ajv-keywords.txt",
    projectUrl: "https://github.com/ajv-validator/ajv-keywords"
  },
  {
    name: "parse-json",
    version: "5.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/parse-json.txt",
    projectUrl: "https://github.com/sindresorhus/parse-json"
  },
  {
    name: "regenerator-runtime",
    version: "0.13.3",
    license: "MIT",
    licenseUrl: "/frontend-license/regenerator-runtime.txt",
    projectUrl: "https://github.com/facebook/regenerator/tree/main"
  },
  {
    name: "tiny-warning",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/tiny-warning.txt",
    projectUrl: "https://github.com/alexreardon/tiny-warning"
  },
  {
    name: "xtend",
    version: "4.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/xtend.txt",
    projectUrl: "https://github.com/Raynos/xtend"
  },
  {
    name: "lodash",
    version: "4.17.15",
    license: "MIT",
    licenseUrl: "/frontend-license/lodash.txt",
    projectUrl: "https://github.com/lodash/lodash"
  },
  {
    name: "webpack-sources",
    version: "1.4.3",
    license: "MIT",
    licenseUrl: "/frontend-license/webpack-sources.txt",
    projectUrl: "https://github.com/webpack/webpack-sources"
  },
  {
    name: "base64-js",
    version: "1.3.1",
    license: "MIT",
    licenseUrl: "/frontend-license/base64-js.txt",
    projectUrl: "https://github.com/beatgammit/base64-js"
  },
  {
    name: "timers-browserify",
    version: "2.0.11",
    license: "MIT",
    licenseUrl: "/frontend-license/timers-browserify.txt",
    projectUrl: "https://github.com/browserify/timers-browserify"
  },
  {
    name: "react-onclickoutside",
    version: "6.9.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react-onclickoutside.txt",
    projectUrl: "https://github.com/Pomax/react-onclickoutside"
  },
  {
    name: "chokidar",
    version: "2.1.8",
    license: "MIT",
    licenseUrl: "/frontend-license/chokidar.txt",
    projectUrl: "https://github.com/paulmillr/chokidar"
  },
  {
    name: "memoize-one",
    version: "5.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/memoize-one.txt",
    projectUrl: "https://github.com/alexreardon/memoize-one"
  },
  {
    name: "upath",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/upath.txt",
    projectUrl: "https://github.com/anodynos/upath"
  },
  {
    name: "value-equal",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/value-equal.txt",
    projectUrl: "https://github.com/mjackson/value-equal"
  },
  {
    name: "resolve-pathname",
    version: "3.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve-pathname.txt",
    projectUrl: "https://github.com/mjackson/resolve-pathname"
  },
  {
    name: "history",
    version: "4.10.1",
    license: "MIT",
    licenseUrl: "/frontend-license/history.txt",
    projectUrl: "https://github.com/remix-run/history"
  },
  {
    name: "react-confirm-alert",
    version: "2.4.1",
    license: "MIT",
    licenseUrl: "/frontend-license/react-confirm-alert.txt",
    projectUrl: "https://github.com/GA-MO/react-confirm-alert"
  },
  {
    name: "acorn",
    version: "7.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/acorn.txt",
    projectUrl: "https://github.com/acornjs/acorn"
  },
  {
    name: "@types/prop-types",
    version: "15.7.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@typesprop-types.txt",
    projectUrl: "https://github.com/DefinitelyTyped/DefinitelyTyped"
  },
  {
    name: "type-fest",
    version: "0.8.1",
    license: "MIT",
    licenseUrl: "/frontend-license/type-fest.txt",
    projectUrl: "https://github.com/sindresorhus/type-fest"
  },
  {
    name: "is-buffer",
    version: "2.0.4",
    license: "MIT",
    licenseUrl: "/frontend-license/is-buffer.txt",
    projectUrl: "https://github.com/feross/is-buffer"
  },
  {
    name: "react-router",
    version: "5.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/react-router.txt",
    projectUrl: "https://github.com/remix-run/react-router"
  },
  {
    name: "react-router-dom",
    version: "5.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/react-router-dom.txt",
    projectUrl: "https://github.com/remix-run/react-router"
  },
  {
    name: "react-input-autosize",
    version: "2.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/react-input-autosize.txt",
    projectUrl: "https://github.com/JedWatson/react-input-autosize"
  },
  {
    name: "react-select",
    version: "3.0.8",
    license: "MIT",
    licenseUrl: "/frontend-license/react-select.txt",
    projectUrl: "https://github.com/JedWatson/react-select/tree/master"
  },
  {
    name: "json5",
    version: "2.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/json5.txt",
    projectUrl: "https://github.com/json5/json5"
  },
  {
    name: "enhanced-resolve",
    version: "4.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/enhanced-resolve.txt",
    projectUrl: "https://github.com/webpack/enhanced-resolve"
  },
  {
    name: "memory-fs",
    version: "0.5.0",
    license: "MIT",
    licenseUrl: "/frontend-license/memory-fs.txt",
    projectUrl: "https://github.com/webpack/memory-fs"
  },
  {
    name: "commander",
    version: "2.20.3",
    license: "MIT",
    licenseUrl: "/frontend-license/commander.txt",
    projectUrl: "https://github.com/tj/commander.js"
  },
  {
    name: "console-browserify",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/console-browserify.txt",
    projectUrl: "https://github.com/browserify/console-browserify"
  },
  {
    name: "source-map-support",
    version: "0.5.16",
    license: "MIT",
    licenseUrl: "/frontend-license/source-map-support.txt",
    projectUrl: "https://github.com/evanw/node-source-map-support"
  },
  {
    name: "vm-browserify",
    version: "1.1.2",
    license: "MIT",
    licenseUrl: "/frontend-license/vm-browserify.txt",
    projectUrl: "https://github.com/browserify/vm-browserify"
  },
  {
    name: "convert-source-map",
    version: "1.7.0",
    license: "MIT",
    licenseUrl: "/frontend-license/convert-source-map.txt",
    projectUrl: "https://github.com/thlorenz/convert-source-map"
  },
  {
    name: "uncontrollable",
    version: "7.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/uncontrollable.txt",
    projectUrl: "https://github.com/jquense/uncontrollable"
  },
  {
    name: "cosmiconfig",
    version: "6.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/cosmiconfig.txt",
    projectUrl: "https://github.com/cosmiconfig/cosmiconfig"
  },
  {
    name: "@types/parse-json",
    version: "4.0.0",
    license: "MIT",
    licenseUrl: "/frontend-license/@typesparse-json.txt",
    projectUrl: "https://github.com/DefinitelyTyped/DefinitelyTyped"
  },
  {
    name: "react-overlays",
    version: "0.9.1",
    license: "MIT",
    licenseUrl: "/frontend-license/react-overlays.txt",
    projectUrl: "https://github.com/react-bootstrap/react-overlays"
  },
  {
    name: "buffer",
    version: "4.9.2",
    license: "MIT",
    licenseUrl: "/frontend-license/buffer.txt",
    projectUrl: "https://github.com/feross/buffer"
  },
  {
    name: "path-to-regexp",
    version: "1.8.0",
    license: "MIT",
    licenseUrl: "/frontend-license/path-to-regexp.txt",
    projectUrl: "https://github.com/pillarjs/path-to-regexp"
  },
  {
    name: "des.js",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/frontend-license/desjs.txt",
    projectUrl: "https://github.com/indutny/des.js"
  },
  {
    name: "import-fresh",
    version: "3.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/import-fresh.txt",
    projectUrl: "https://github.com/sindresorhus/import-fresh"
  },
  {
    name: "Newtonsoft.Json",
    version: "12.0.3",
    license: "MIT",
    licenseUrl: "/backend-license/NewtonsoftJson.txt",
    projectUrl: "https://github.com/JamesNK/Newtonsoft.Json"
  },
  {
    name: "react-is",
    version: "16.12.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react-is.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "scheduler",
    version: "0.18.0",
    license: "MIT",
    licenseUrl: "/frontend-license/scheduler.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "elliptic",
    version: "6.5.2",
    license: "MIT",
    licenseUrl: "/frontend-license/elliptic.txt",
    projectUrl: "https://github.com/indutny/elliptic"
  },
  {
    name: "acorn",
    version: "6.4.0",
    license: "MIT",
    licenseUrl: "/frontend-license/acorn.txt",
    projectUrl: "https://github.com/acornjs/acorn"
  },
  {
    name: "bluebird",
    version: "3.7.2",
    license: "MIT",
    licenseUrl: "/frontend-license/bluebird.txt",
    projectUrl: "https://github.com/petkaantonov/bluebird"
  },
  {
    name: "core-js",
    version: "2.6.11",
    license: "MIT",
    licenseUrl: "/frontend-license/core-js.txt",
    projectUrl: "https://github.com/zloirock/core-js"
  },
  {
    name: "babel-plugin-macros",
    version: "2.8.0",
    license: "MIT",
    licenseUrl: "/frontend-license/babel-plugin-macros.txt",
    projectUrl: "https://github.com/kentcdodds/babel-plugin-macros"
  },
  {
    name: "terser-webpack-plugin",
    version: "1.4.3",
    license: "MIT",
    licenseUrl: "/frontend-license/terser-webpack-plugin.txt",
    projectUrl: "https://github.com/webpack-contrib/terser-webpack-plugin"
  },
  {
    name: "csstype",
    version: "2.6.8",
    license: "MIT",
    licenseUrl: "/frontend-license/csstype.txt",
    projectUrl: "https://github.com/frenic/csstype"
  },
  {
    name: "fast-json-stable-stringify",
    version: "2.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/fast-json-stable-stringify.txt",
    projectUrl: "https://github.com/epoberezkin/fast-json-stable-stringify"
  },
  {
    name: "react-bootstrap",
    version: "0.33.1",
    license: "MIT",
    licenseUrl: "/frontend-license/react-bootstrap.txt",
    projectUrl: "https://github.com/react-bootstrap/react-bootstrap"
  },
  {
    name: "ua-parser-js",
    version: "0.7.21",
    license: "MIT",
    licenseUrl: "/frontend-license/ua-parser-js.txt",
    projectUrl: "https://github.com/faisalman/ua-parser-js"
  },
  {
    name: "@emotion/unitless",
    version: "0.7.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionunitless.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/memoize",
    version: "0.7.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionmemoize.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "babel-plugin-emotion",
    version: "10.0.27",
    license: "MIT",
    licenseUrl: "/frontend-license/babel-plugin-emotion.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main/packages/babel-plugin-emotion"
  },
  {
    name: "@emotion/hash",
    version: "0.7.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionhash.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/css",
    version: "10.0.27",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotioncss.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/serialize",
    version: "0.11.15",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionserialize.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/sheet",
    version: "0.9.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionsheet.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/weak-memoize",
    version: "0.2.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionweak-memoize.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/utils",
    version: "0.11.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionutils.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/core",
    version: "10.0.27",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotioncore.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main/removed-packages/core"
  },
  {
    name: "@emotion/cache",
    version: "10.0.27",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotioncache.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main"
  },
  {
    name: "@emotion/stylis",
    version: "0.8.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@emotionstylis.txt",
    projectUrl: "https://github.com/emotion-js/emotion/tree/main/packages/stylis"
  },
  {
    name: "p-limit",
    version: "2.2.2",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/sindresorhus/p-limit"
  },
  {
    name: "fast-deep-equal",
    version: "3.1.1",
    license: "MIT",
    licenseUrl: "/frontend-license/fast-deep-equal.txt",
    projectUrl: "https://github.com/epoberezkin/fast-deep-equal"
  },
  {
    name: "readable-stream",
    version: "2.3.7",
    license: "MIT",
    licenseUrl: "/frontend-license/readable-stream.txt",
    projectUrl: "https://github.com/nodejs/readable-stream"
  },
  {
    name: "events",
    version: "3.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/events.txt",
    projectUrl: "https://github.com/browserify/events"
  },
  {
    name: "@babel/code-frame",
    version: "7.8.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelcode-frame.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/highlight",
    version: "7.8.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelhighlight.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/types",
    version: "7.8.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@babeltypes.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/helper-module-imports",
    version: "7.8.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelhelper-module-imports.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "kind-of",
    version: "6.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/kind-of.txt",
    projectUrl: "https://github.com/jonschlinkert/kind-of"
  },
  {
    name: "schema-utils",
    version: "2.6.4",
    license: "MIT",
    licenseUrl: "/frontend-license/schema-utils.txt",
    projectUrl: "https://github.com/webpack/schema-utils"
  },
  {
    name: "ajv",
    version: "6.11.0",
    license: "MIT",
    licenseUrl: "/frontend-license/ajv.txt",
    projectUrl: "https://github.com/ajv-validator/ajv"
  },
  {
    name: "readable-stream",
    version: "3.5.0",
    license: "MIT",
    licenseUrl: "/frontend-license/readable-stream.txt",
    projectUrl: "https://github.com/nodejs/readable-stream"
  },
  {
    name: "@types/react",
    version: "16.9.19",
    license: "MIT",
    licenseUrl: "/frontend-license/@typesreact.txt",
    projectUrl: "https://github.com/DefinitelyTyped/DefinitelyTyped"
  },
  {
    name: "pako",
    version: "1.0.11",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/nodeca/pako"
  },
  {
    name: "tiny-invariant",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/frontend-license/MITlicense",
    projectUrl: "https://github.com/alexreardon/tiny-invariant"
  },
  {
    name: "@babel/runtime",
    version: "7.8.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelruntime.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/runtime-corejs2",
    version: "7.8.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelruntime-corejs2.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "PasswordGenerator",
    version: "2.0.5",
    license: "MIT",
    licenseUrl: "/backend-license/PasswordGenerator.txt",
    projectUrl: "https://github.com/prjseal/PasswordGenerator/"
  },
  {
    name: "resolve",
    version: "1.15.1",
    license: "MIT",
    licenseUrl: "/frontend-license/resolve.txt",
    projectUrl: "https://github.com/browserify/resolve"
  },
  {
    name: "webpack",
    version: "5.76.0",
    license: "MIT",
    licenseUrl: "/frontend-license/webpack.txt",
    projectUrl: "https://github.com/webpack/webpack"
  },
  {
    name: "scheduler",
    version: "0.19.1",
    license: "MIT",
    licenseUrl: "/frontend-license/scheduler.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "System.Buffers",
    version: "4.5.1",
    license: "MIT",
    licenseUrl: "/backend-license/SystemBuffers.txt",
    projectUrl: "https://github.com/dotnet/maintenance-packages"
  },
  {
    name: "System.Threading.Tasks.Extensions",
    version: "4.5.4",
    license: "MIT",
    licenseUrl: "/backend-license/SystemThreadingTasksExtensions.txt",
    projectUrl: "https://github.com/dotnet/maintenance-packages"
  },
  {
    name: "System.Memory",
    version: "4.5.4",
    license: "MIT",
    licenseUrl: "/backend-license/SystemMemory.txt",
    projectUrl: "https://github.com/dotnet/maintenance-packages"
  },
  {
    name: "react-csv",
    version: "2.0.3",
    license: "MIT",
    licenseUrl: "/frontend-license/react-csv.txt",
    projectUrl: "https://github.com/react-csv/react-csv"
  },
  {
    name: "jQuery",
    version: "3.5.1",
    license: "MIT",
    licenseUrl: "/backend-license/jQuery.txt",
    projectUrl: "https://www.nuget.org/packages/jQuery"
  },
  {
    name: "react-multi-carousel",
    version: "2.5.5",
    license: "MIT",
    licenseUrl: "/frontend-license/react-multi-carousel.txt",
    projectUrl: "https://github.com/YIZHUANG/react-multi-carousel"
  },
  {
    name: "debug",
    version: "4.2.0",
    license: "MIT",
    licenseUrl: "/frontend-license/debug.txt",
    projectUrl: "https://github.com/debug-js/debug"
  },
  {
    name: "log4net.ElasticSearch",
    version: "2.5.0",
    license: "MIT",
    licenseUrl: "/backend-license/log4netElasticSearch.txt",
    projectUrl: "https://bruno-garcia.github.io/log4net.ElasticSearch/"
  },
  {
    name: "@babel/code-frame",
    version: "7.10.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelcode-frame.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/highlight",
    version: "7.10.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelhighlight.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/helper-validator-identifier",
    version: "7.10.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babel/helper-validator-identifier.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "@babel/helper-module-imports",
    version: "7.10.4",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelhelper-module-imports.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "lodash",
    version: "4.17.20",
    license: "MIT",
    licenseUrl: "/frontend-license/lodash.txt",
    projectUrl: "https://github.com/lodash/lodash"
  },
  {
    name: "@babel/types",
    version: "7.11.5",
    license: "MIT",
    licenseUrl: "/frontend-license/@babeltypes.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "Namotion.Reflection",
    version: "1.0.14",
    license: "MIT",
    licenseUrl: "/backend-license/NamotionReflection.txt",
    projectUrl: "https://github.com/RicoSuter/Namotion.Reflection"
  },
  {
    name: "System.IdentityModel.Tokens.Jwt",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemIdentityModelTokensJwt.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "Microsoft.IdentityModel.Tokens",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftIdentityModelTokens.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "Microsoft.IdentityModel.Logging",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftIdentityModelLogging.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "Microsoft.IdentityModel.Protocols",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftIdentityModelProtocols.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "Microsoft.IdentityModel.JsonWebTokens",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftIdentityModelJsonWebTokens.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "Microsoft.IdentityModel.Protocols.OpenIdConnect",
    version: "6.8.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftIdentityModelProtocolsOpenIdConnect.txt",
    projectUrl: "https://github.com/AzureAD/azure-activedirectory-identitymodel-extensions-for-dotnet"
  },
  {
    name: "react-dom",
    version: "18.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react-dom.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "react",
    version: "18.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react.txt",
    projectUrl: "https://github.com/facebook/react"
  },
  {
    name: "System.Runtime.CompilerServices.Unsafe",
    version: "5.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemRuntimeCompilerServicesUnsafe.txt",
    projectUrl: "https://github.com/dotnet/maintenance-packages"
  },
  {
    name: "System.Text.Encodings.Web",
    version: "5.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemTextEncodingsWeb.txt",
    projectUrl: "https://api.nuget.org/packages/"
  },
  {
    name: "Microsoft.Bcl.AsyncInterfaces",
    version: "8.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftBclAsyncInterfaces.txt",
    projectUrl: "https://github.com/dotnet/runtime"
  },
  {
    name: "System.Text.Json",
    version: "8.0.5",
    license: "MIT",
    licenseUrl: "/backend-license/SystemTextJson.txt",
    projectUrl: "https://github.com/dotnet/runtime"
  },
  {
    name: "System.Threading.Channels",
    version: "5.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemThreadingChannels.txt",
    projectUrl: "https://github.com/dotnet/runtime"
  },
  {
    name: "NJsonSchema",
    version: "10.3.1",
    license: "MIT",
    licenseUrl: "/backend-license/NJsonSchema.txt",
    projectUrl: "https://github.com/RicoSuter/NJsonSchema"
  },
  {
    name: "System.Diagnostics.PerformanceCounter",
    version: "5.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemDiagnosticsPerformanceCounter.txt",
    projectUrl: "https://github.com/dotnet/runtime"
  },
  {
    name: "Pipelines.Sockets.Unofficial",
    version: "2.2.0",
    license: "MIT",
    licenseUrl: "/backend-license/PipelinesSocketsUnofficial.txt",
    projectUrl: "https://github.com/mgravell/Pipelines.Sockets.Unofficial"
  },
  {
    name: "StackExchange.Redis",
    version: "2.2.4",
    license: "MIT",
    licenseUrl: "/backend-license/StackExchangeRedis.txt",
    projectUrl: "https://github.com/StackExchange/StackExchange.Redis/"
  },
  {
    name: "Microsoft.NETCore.UniversalWindowsPlatform",
    version: "6.2.11",
    license: "MIT",
    licenseUrl: "/backend-license/MicrosoftNETCoreUniversalWindowsPlatform.txt",
    projectUrl: "https://github.com/Microsoft/dotnet/blob/main/releases/UWP/README.md"
  },
  {
    name: "react-bootstrap-dialog",
    version: "0.13.0",
    license: "MIT",
    licenseUrl: "/frontend-license/react-bootstrap-dialog.txt",
    projectUrl: "https://github.com/akiroom/react-bootstrap-dialog"
  },
  {
    name: "System.IO.Pipelines",
    version: "5.0.1",
    license: "MIT",
    licenseUrl: "/backend-license/SystemIOPipelines.txt",
    projectUrl: "https://github.com/dotnet/runtime"
  },
  {
    name: "regenerator-runtime",
    version: "0.13.11",
    license: "MIT",
    licenseUrl: "/frontend-license/regenerator-runtime.txt",
    projectUrl: "https://github.com/facebook/regenerator/tree/main"
  },
  {
    name: "node-fetch",
    version: "2.6.11",
    license: "MIT",
    licenseUrl: "/frontend-license/node-fetch.txt",
    projectUrl: "https://github.com/node-fetch/node-fetch"
  },
  {
    name: "cross-fetch",
    version: "3.1.6",
    license: "MIT",
    licenseUrl: "/frontend-license/cross-fetch.txt",
    projectUrl: "https://github.com/lquixada/cross-fetch"
  },
  {
    name: "i18next-http-backend",
    version: "2.2.1",
    license: "MIT",
    licenseUrl: "/frontend-license/i18next-http-backend.txt",
    projectUrl: "https://github.com/i18next/i18next-http-backend"
  },
  {
    name: "@babel/runtime",
    version: "7.22.3",
    license: "MIT",
    licenseUrl: "/frontend-license/@babelruntime.txt",
    projectUrl: "https://github.com/babel/babel"
  },
  {
    name: "i18next-browser-languagedetector",
    version: "7.0.2",
    license: "MIT",
    licenseUrl: "/frontend-license/i18next-browser-languagedetector.txt",
    projectUrl: "https://github.com/i18next/i18next-browser-languageDetector"
  },
  {
    name: "i18next",
    version: "22.5.1",
    license: "MIT",
    licenseUrl: "/frontend-license/i18next.txt",
    projectUrl: "https://github.com/i18next/i18next"
  },
  {
    name: "Npgsql",
    version: "7.0.4",
    license: "PostgreSQL",
    licenseUrl: "/backend-license/Npgsql.txt",
    projectUrl: "https://github.com/npgsql/npgsql"
  },
  {
    name: "Npgsql.Json.NET",
    version: "7.0.4",
    license: "PostgreSQL",
    licenseUrl: "/backend-license/NpgsqlJsonNET.txt",
    projectUrl: "https://github.com/npgsql/npgsql"
  },
  {
    name: "System.Numerics.Vectors",
    version: "4.5.0",
    license: "MIT",
    licenseUrl: "/backend-license/SystemNumericsVectors.txt",
    projectUrl: "https://github.com/dotnet/maintenance-packages"
  },
  {
    name: "remotion.linq",
    version: "2.2.0",
    license: "Apache 2.0",
    licenseUrl: "/backend-license/RemotionLinq.txt",
    projectUrl: "https://www.nuget.org/packages/Remotion.Linq"
  },
  {
    name: "system.security.cryptography.primitives",
    version: "4.3.0",
    license: "Microsoft .NET Library",
    licenseUrl: "backend-license/SystemSecurityCryptographyPrimitives.txt",
    projectUrl: "https://api.nuget.org/packages/System.Security.Cryptography.Primitives"
  },
  {
    name: "system.io",
    version: "4.3.0",
    license: "Microsoft .NET Library",
    licenseUrl: "backend-license/systemIo.txt",
    projectUrl: "https://api.nuget.org/packages/System.IO"
  },
  {
    name: "system.security.cryptography.encoding",
    version: "4.3.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/systemSecurityCryptographyEncoding.txt",
    projectUrl: "https://www.nuget.org/packages/System.Security.Cryptography.Encoding"
  },
  {
    name: "system.valuetuple",
    version: "4.5.0",
    license: "MIT",
    licenseUrl: "/backend-license/systemValuetuple.txt",
    projectUrl: "https://www.nuget.org/packages/System.ValueTuple"
  },
  {
    name: "system.io.compression",
    version: "4.3.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/systemIoCompression.txt",
    projectUrl: "https://www.nuget.org/packages/System.IO.Compression"
  },
  {
    name: "system.runtime.interopservices.runtimeinformation",
    version: "4.3.0",
    license: "Microsoft .NET Library",
    licenseUrl: "/backend-license/systemRuntimeInteropservicesRuntimeinformation.txt",
    projectUrl: "https://www.nuget.org/packages/System.Runtime.InteropServices.RuntimeInformation"
  },
  {
    name: "toastr",
    version: "2.0.3",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.0.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.2.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.3.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.0.2",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.2",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "2.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.3.0",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.5",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "2.0.2",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://api.nuget.org/packages/"
  },
  {
    name: "toastr",
    version: "1.1.4.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.2.0",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.2.2",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.3",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.0",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://api.nuget.org/packages/"
  },
  {
    name: "toastr",
    version: "1.1.4.2",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "2.0.1",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.1.4",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.0.0",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  },
  {
    name: "toastr",
    version: "1.0.3",
    license: "MIT",
    licenseUrl: "/backend-license/toastr.txt",
    projectUrl: "https://github.com/CodeSeven/toastr"
  }
];

export default libraries;
